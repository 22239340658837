<template>
  <div>
    <vue-element-loading :active="loading" spinner="bar-fade-scale" :background-color="'rgba(0, 0, 0, 0.3)'">
      <semipolar-spinner :animation-duration="1200" :size="95" color="#ff1d5e" />
    </vue-element-loading>
    <v-file-input
      color="deep-purple accent-4"
      counter
      v-model="selected_logoFile"
      accept="image/*"
      :label="`Enregistrer le logo de l'entité <${company_type} ${company_name}>`"
      prepend-icon="mdi-file-image"
      chips
      :rules="logoRules"
      :show-size="1000"
    ></v-file-input>
    <v-btn
      class="mt-4"
      color="green lighten-1 white--text"
      :disabled="selected_logoFile == null || selected_logoFile.length <= 0 || loading"
      block
      @click="saveLogo()"
      >Enregistrer</v-btn
    >
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="mt-4" color="red lighten-1 white--text" :disabled="!saved_logo" block @click="deleteSavedLogo()"
          >Supprimer le logo existant</v-btn
        >
      </template>
      <span>{{ saved_logo }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";
import snackBar from "@/utils/mixins/snackBar";

export default {
  name: "LogoUpload",

  components: { SemipolarSpinner },
  props: {
    company_type: {
      type: String,
      default: "",
    },
    current_logo: { type: String },
    company_name: { type: String },
  },
  mixins: [snackBar],

  data() {
    return {
      selected_logoFile: [],
      mapping: {
        concession: { add_logo: "/concession/logo", remove_logo: "/concession/logo" },
        groupe: { add_logo: "/groupe/logo", remove_logo: "/groupe/logo" },
        franchise: { add_logo: "/franchise/logo", remove_logo: "/franchise/logo" },
      },
      saved_logo: null,

      loading: false,

      logoRules: [(value) => !value || value.size < 5000000 || "Le logo doit avoir une taille en octet inférieur à 5MB"],
    };
  },

  methods: {
    async deleteSavedLogo() {
      try {
        let url =
          this.company_type === "groupe" || this.company_type === "franchise"
            ? `${this.mapping[this.company_type].remove_logo}/${this.company_name}`
            : this.mapping[this.company_type].remove_logo;

        this.loading = true;
        await this.$axios.delete(url);
        this.$emit("on-remove-logo", this.company_name);
        this.logo = null;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(!error?.response?.data?.message ?? error);
      } finally {
        this.loading = false;
      }
    },

    async saveLogo() {
      try {
        let url =
          this.company_type === "groupe" || this.company_type === "franchise"
            ? `${this.mapping[this.company_type].add_logo}/${this.company_name}`
            : this.mapping[this.company_type].add_logo;

        this.loading = true;
        const formData = new FormData();
        formData.append("file", this.selected_logoFile);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        await this.$axios.post(url, formData, config);
        this.displaySuccessSnack(`Logo "${this.selected_logoFile.name}" à bien été enregistré`);

        this.$emit("on-save-logo", { name: this.company_name, logo: this.selected_logoFile });
        this.saved_logo = this.selected_logoFile.name;
        this.selected_logoFile = null;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(!error?.response?.data?.message ?? error);
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    current_logo: {
      handler: function(val) {
        this.saved_logo = val;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle-letter-spacing {
  letter-spacing: 3px !important;
}
</style>
