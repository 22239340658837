<template>
  <v-card>
    <v-card-title class="text-center justify-center py-6">
      <h1 class="font-weight-bold text-h4 pb-3">Gestion des données</h1>
    </v-card-title>
    <v-card-subtitle class="text-center text-overline font-weight-bold orange--text subtitle-letter-spacing pb-5"
      >{{ type }} {{ name }}</v-card-subtitle
    >
    <v-tabs grow centered v-model="tab">
      <v-tab>Logo</v-tab>
      <v-tab>Slides personnalisés</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card color="basil" flat>
          <v-card-text>
            <v-scale-transition>
              <v-row justify="center" v-show="getLogoName && getLogoUrl" class="text-center" no-gutters>
                <v-col cols="12" class="text-h6 font-weight-regular mb-6">Logo actuellement enregistré</v-col>
                <v-col cols="12"
                  ><v-img
                    :src="getLogoUrl"
                    contain
                    max-width="300px"
                    @click="downloadLogo"
                    class="mx-auto box grow"
                    style="cursor: pointer;"
                  ></v-img
                ></v-col>
                <v-col cols="12" class="my-6"><v-divider></v-divider></v-col>
              </v-row>
            </v-scale-transition>
            <LogoUpload
              v-on:on-save-logo="handleSaveLogo"
              v-on:on-remove-logo="handleRemoveLogo"
              :current_logo="getLogoName"
              :company_type="type"
              :company_name="name"
          /></v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card color="basil" flat>
          <v-expansion-panels :multiple="true" focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>Catalogue</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Catalogue
                  class="pt-9"
                  :custom_slide_list="custom_slides"
                  v-on:on-remove-slide="handleRemoveSlide"
                  :removable="true"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Ajouter un slide</v-expansion-panel-header>
              <v-expansion-panel-content>
                <CustomSlideUpload
                  :company_name="name"
                  :current_custom_slides="custom_slides"
                  v-on:on-save-slide="handleSaveSlide"
                  :date_validity="true"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import LogoUpload from "@/components/Company/LogoUpload";
import Catalogue from "@/components/Company/Catalogue";
import CustomSlideUpload from "@/components/Company/CustomSlideUpload";
import snackBar from "@/utils/mixins/snackBar";

export default {
  name: "Concession",
  components: { LogoUpload, Catalogue, CustomSlideUpload },
  mixins: [snackBar],

  data() {
    return {
      tab: null,

      end_point: "/concession/catalogue",

      type: "concession",
      logo: null,
      custom_slides: null,
      name: "",
    };
  },
  async created() {
    await this.init();
  },

  methods: {
    async init() {
      try {
        let company = (await this.$axios.get(this.end_point)).data?.company;

        this.type = this.$route.params.type;
        this.logo = company.logo;
        this.custom_slides = company.custom_slide || [];
        this.name = company.name;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },

    handleSaveLogo(item) {
      this.logo = { url: URL.createObjectURL(item.logo), name: item.logo.name };
    },

    handleRemoveLogo() {
      this.logo = null;
    },

    handleRemoveSlide(item) {
      this.custom_slides.splice(item.idx, 1);
    },

    async downloadLogo() {
      try {
        const { url } = (await this.$axios.get(`/concession/link/logo`)).data;

        const obj = window.URL.createObjectURL(new Blob([url]));
        const link = document.createElement("a");
        link.href = url;

        document.body.appendChild(link);
        link.click();

        link.remove();
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },

    handleSaveSlide(slide) {
      this.custom_slides.push(slide.data);
    },
  },
  computed: {
    getLogoName() {
      return this.logo?.name || null;
    },
    getLogoUrl() {
      return this.logo?.url || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
}
.box:hover {
  box-shadow: 0 0 22px rgba(150, 150, 150, 0.05);
}
</style>
