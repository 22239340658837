<template>
  <v-row no-gutters>
    <vue-element-loading :active="loading" spinner="bar-fade-scale" :background-color="'rgba(0, 0, 0, 0.3)'">
      <semipolar-spinner :animation-duration="1200" :size="95" color="#ff1d5e" />
    </vue-element-loading>
    <v-col v-for="(slide, idx) in custom_slide_list" :key="slide._id" cols="4" class="pa-2 p-relative">
      <v-row
        no-gutters
        justify="center"
        align="center"
        :class="{ 'selected-slide': (selectable && selected_slides[idx]) || highlight }"
        class="rounded"
        style="height: 100%; width: 100%"
      >
        <v-col class="text-center">
          <v-row align="center" justify="end" class="p-absolute foreground top-left">
            <v-btn icon dark v-if="selectable" @click="select_element(idx, !selected_slides[idx])">
              <v-icon>
                {{ selected_slides[idx] ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }}
              </v-icon>
            </v-btn>
            <v-btn icon dark class="text-center" v-if="removable" @click="deleteSlide({ slide, idx })">
              <v-icon color="orange" class="pointer grow pt-1" style="left: -6px; top: 0px; height: 20px"
                >mdi-delete-circle-outline
              </v-icon>
            </v-btn>
          </v-row>
          <div v-if="slide.type === 'img'" class="grow-1 py-4">
            <v-img
              @error="emitErrorImg"
              :src="slide.url"
              max-height="200px"
              :class="{ pointer: selectable }"
              @click="select_element(idx, !selected_slides[idx])"
              contain
            >
              <template v-slot:placeholder>
                <v-row align="center" justify="center" style="width: 100%; height: 100%">
                  <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
          <div v-if="slide.type === 'video'" class="p-relative neon-cruip pointer" style="max-width: 100%">
            <v-row class="p-absolute" style="width: 100%; height: 100%" @click="videoModalActive = true"> </v-row>
            <c-modal
              id="vimeo-modal"
              :active.sync="videoModalActive"
              :video="`https://player.vimeo.com/video/${slide.uri.split('/')[2]}`"
              video-tag="iframe"
            />
            <iframe
              :src="`https://player.vimeo.com/video/${slide.uri.split('/')[2]}`"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            ></iframe>
          </div>
          <v-row class="flex-column text-start" no-gutters>
            <span class="text-subtitle-2 d-block mb-3 text--primary">{{ slide.name }}</span>
            <span class="text-caption text--secondary" v-show="slide.created_at">
              ajouté le : {{ slide.created_at ? formatDate(new Date(slide.created_at)) : "inconnu" }}</span
            >
            <span class="text-caption text--secondary" v-show="slide.expiration_date">
              expire le : {{ slide.expiration_date ? formatDate(new Date(slide.expiration_date)) : "inconnu" }}</span
            >
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";
import CModal from "@/components/cruip_template/elements/Modal";

import user from "@/utils/mixins/user";

export default {
  name: "Catalogue",

  components: { SemipolarSpinner, CModal },

  props: {
    company_type: {
      type: String,
      default: "concession",
    },
    custom_slide_list: { type: Array, default: () => [] },
    selected_custom_slide_list: [Array, String, Object], // Array of ids
    company_name: { type: String, default: "" },
    removable: false,
    selectable: false,
    highlight: false,
  },

  mixins: [user],

  data() {
    return {
      mapping: {
        concession: { remove_slide: "/concession/custom_slide", in_store: "concession_cSlides_list" },
        groupe: { remove_slide: "/groupe/custom_slide", in_store: "groupe_cSlides_list" },
        franchise: { remove_slide: "/franchise/custom_slide", in_store: "franchise_cSlides_list" },
      },
      loading: false,
      selected_slides: [], // Array of boolean
      videoModalActive: false,
    };
  },

  mounted() {
    if (this.selected_custom_slide_list != "all") {
      if (
        this.company_type == "concession" &&
        this.selected_custom_slide_list &&
        Array.isArray(this.selected_custom_slide_list)
      ) {
        for (let i = 0; i < this.custom_slide_list.length; i++) {
          const elem = this.selected_custom_slide_list.find((elem) => elem == this.custom_slide_list[i]._id);
          if (elem && this.selected_slides[i] == false) {
            this.select_element(i, true);
          }
        }
      } else if (this.selected_custom_slide_list && Array.isArray(this.selected_custom_slide_list)) {
        const company = this.selected_custom_slide_list.find((el) => el.name == this.company_name);
        if (company) {
          for (let i = 0; i < this.custom_slide_list.length; i++) {
            const elem = company.slides.find((elem) => elem == this.custom_slide_list[i]._id);
            if (elem && this.selected_slides[i] == false) {
              this.select_element(i, true);
            }
          }
        }
      }
    }
  },

  methods: {
    select_element(idx, value) {
      if (this.selectable && this.selected_slides.length > idx) {
        this.selected_slides.splice(idx, 1, value);
        this.$emit("selected", {
          index: idx,
          value: value ?? this.selected_slides[idx],
          type: this.company_type,
          company_name: this.company_name,
        });
      }
    },

    async deleteSlide(item) {
      let url =
        this.company_type === "groupe" || this.company_type === "franchise"
          ? `${this.mapping[this.company_type].remove_slide}/${this.company_name}/${item.slide._id}`
          : this.mapping[this.company_type].remove_slide;

      let data = this.company_type === "concession" ? { data: { customSlideId: item.slide._id } } : {};

      if (confirm("Voulez-vous vraiment supprimer l'élement ?")) {
        try {
          this.loading = true;
          await this.$axios.delete(url, data);
          this.$emit("on-remove-slide", { name: this.company_name, ...item });
        } catch (error) {
          console.error(error);
          this.displayErrorSnack(error);
        } finally {
          this.loading = false;
        }
      }
    },

    emitErrorImg() {
      this.$emit("on-img-error");
    },

    formatDate(date) {
      if (date?.length <= 0) return "";

      const format = new Intl.DateTimeFormat("fr-FR", { weekday: "long", day: "2-digit", month: "long", year: "numeric" });
      const [{ value: weekday }, , { value: day }, , { value: month }, , { value: year }] = format.formatToParts(new Date(date));

      return `${weekday} ${day} ${month} ${year}`;
    },
  },
  watch: {
    custom_slide_list: {
      handler: function(val) {
        if (this.selectable) {
          this.selected_slides = [];
          if (val?.length) this.selected_slides = Array(val.length).fill(false, 0, val.length);
        }
      },
      immediate: true,
    },
    selectable: {
      handler: function(val) {
        this.selected_slides = this.selected_slides.map((el) => (el = false));
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
iframe {
  max-width: 100% !important;
  width: auto !important;
  height: auto !important;
}

.foreground {
  z-index: 1;
}

.top-left {
  top: 4%;
  right: 4%;
}

.selected-slide {
  background-color: rgba(128, 128, 128, 0.616);
}

.rounded {
  border-radius: 10px;
}
</style>
