<template>
  <v-container>
    <v-row justify="center" no-gutters>
      <v-col v-if="type" cols="12" md="8">
        <component :is="mappingComponent[type]"></component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Concession from "@/components/Company/Concession";
import Groupe from "@/components/Company/Groupe";

export default {
  name: "Company",

  data() {
    return {
      type: null,

      mappingComponent: { concession: Concession, groupe: Groupe, franchise: Groupe },
    };
  },

  created() {
    this.type = this.$route.params.type;
  },

  beforeRouteEnter(to, from, next) {
    if (!to?.params?.type || !["concession", "groupe", "franchise"].includes(to.params.type)) next("/");
    else next();
  },
};
</script>

<style lang="scss" scoped></style>
