<template>
  <v-card>
    <v-card-title class="text-center justify-center py-6">
      <h1 class="font-weight-bold text-h4 pb-3">Gestion des données</h1>
    </v-card-title>
    <v-card-subtitle class="text-center text-overline font-weight-bold orange--text subtitle-letter-spacing pb-5">{{
      type
    }}</v-card-subtitle>

    <v-tabs vertical v-model="tab">
      <v-tab
        v-for="(partGroupe, index) in groupe"
        :key="partGroupe[`ref_${type}_by_id`].name"
        :class="{ 'orange--text': index == tab }"
      >
        {{ partGroupe[`ref_${type}_by_id`].name }}
      </v-tab>

      <v-tab-item v-for="partGroupe in groupe" :key="partGroupe._id">
        <v-card color="basil" flat>
          <v-expansion-panels :multiple="true" focusable>
            <v-expansion-panel :disabled="partGroupe[`ref_${type}_by_id`].custom_slide.length <= 0">
              <v-expansion-panel-header>Catalogue</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Catalogue
                  class="pt-9"
                  :company_type="type"
                  :company_name="partGroupe[`ref_${type}_by_id`].name"
                  :custom_slide_list="partGroupe[`ref_${type}_by_id`].custom_slide"
                  v-on:on-remove-slide="handleRemoveSlide"
                  v-on:on-img-error="init"
                  :removable="true"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Ajouter un slide</v-expansion-panel-header>
              <v-expansion-panel-content>
                <CustomSlideUpload
                  :company_type="type"
                  :company_name="partGroupe[`ref_${type}_by_id`].name"
                  :current_custom_slides="partGroupe[`ref_${type}_by_id`].custom_slide"
                  v-on:on-save-slide="handleSaveSlide"
                  :date_validity="true"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Logo entreprise</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-scale-transition>
                    <v-row
                      justify="center"
                      v-show="getLogoName(partGroupe[`ref_${type}_by_id`]) && getLogoUrl(partGroupe[`ref_${type}_by_id`])"
                      class="text-center"
                      no-gutters
                    >
                      <v-col cols="12" class="text-h6 font-weight-regular mb-6">Logo actuellement enregistré</v-col>
                      <v-col cols="12"
                        ><v-img
                          @error="init"
                          :src="getLogoUrl(partGroupe[`ref_${type}_by_id`])"
                          contain
                          max-width="300px"
                          @click="downloadLogo(partGroupe[`ref_${type}_by_id`].name)"
                          class="mx-auto box grow"
                          style="cursor: pointer;"
                        ></v-img
                      ></v-col>
                      <v-col cols="12" class="my-6"><v-divider></v-divider></v-col>
                    </v-row>
                  </v-scale-transition>
                  <LogoUpload
                    v-on:on-save-logo="handleSaveLogo"
                    v-on:on-remove-logo="handleRemoveLogo"
                    :current_logo="getLogoName(partGroupe[`ref_${type}_by_id`])"
                    :company_type="type"
                    :company_name="partGroupe[`ref_${type}_by_id`].name"/></v-card-text></v-expansion-panel-content
            ></v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import LogoUpload from "@/components/Company/LogoUpload";
import Catalogue from "@/components/Company/Catalogue";
import CustomSlideUpload from "@/components/Company/CustomSlideUpload";

import snackBar from "@/utils/mixins/snackBar";

export default {
  name: "Groupe",
  components: { LogoUpload, Catalogue, CustomSlideUpload },
  mixins: [snackBar],

  data() {
    return {
      tab: null,

      mapping: { groupe: "/groupe/catalogue", franchise: "/franchise/catalogue" },

      type: "groupe",

      groupe: [],
    };
  },
  async created() {
    await this.init();
  },

  methods: {
    async init() {
      try {
        this.type = this.$route.params.type;

        this.groupe = (await this.$axios.get(this.mapping[this.type], { params: { supervisor: true } })).data?.[this.type];
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },

    handleSaveLogo(item) {
      let index = this.groupe.findIndex((gr) => gr[`ref_${this.type}_by_id`].name == item.name);

      let key = `ref_${this.type}_by_id`;

      let tmp = {
        [key]: {
          ...this.groupe[index][`ref_${this.type}_by_id`],
          logo: {
            url: URL.createObjectURL(item.logo),
            name: item.logo.name,
          },
        },
      };

      this.groupe.splice(index, 1, {
        ...this.groupe[index],
        ...tmp,
      });
    },

    handleRemoveLogo(name) {
      this.groupe.find((gr) => gr[`ref_${this.type}_by_id`].name == name)[`ref_${this.type}_by_id`].logo = null;
    },

    handleRemoveSlide(item) {
      let idx = null;

      if ((idx = this.groupe.findIndex((gr) => gr[`ref_${this.type}_by_id`].name === item.name)) >= 0)
        this.groupe[idx][`ref_${this.type}_by_id`].custom_slide.splice(item.idx, 1);
    },

    async downloadLogo(name) {
      try {
        const { url } = (await this.$axios.get(`/${this.type}/link/logo`, { params: { [`${this.type}_name`]: name } })).data;

        const obj = window.URL.createObjectURL(new Blob([url]));
        const link = document.createElement("a");
        link.href = url;

        document.body.appendChild(link);
        link.click();

        link.remove();
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },

    handleSaveSlide(slide) {
      this.groupe
        .find((gr) => gr[`ref_${this.type}_by_id`].name === slide.name)
        [`ref_${this.type}_by_id`].custom_slide.push(slide.data);
    },
    getLogoName(partGroupe) {
      return partGroupe.logo?.name || null;
    },
    getLogoUrl(partGroupe) {
      return partGroupe.logo?.url || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
}
.box:hover {
  box-shadow: 0 0 22px rgba(150, 150, 150, 0.05);
}
</style>
