<template>
  <div>
    <vue-element-loading :active="loading" spinner="bar-fade-scale" :background-color="'rgba(0, 0, 0, 0.3)'">
      <semipolar-spinner :animation-duration="1200" :size="95" color="#ff1d5e" />
    </vue-element-loading>
    <v-file-input
      color="deep-purple accent-4"
      counter
      multiple
      v-model="selected_files"
      accept="image/*, video/*"
      :label="`Ajouter un slide à l'entité <${company_type} ${company_name}>`"
      prepend-icon="mdi-video-image"
      chips
      :rules="slidesRules"
      :show-size="1000"
    ></v-file-input>

    <v-dialog v-if="date_validity" ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formatDate"
          label="Selectionner une date de fin de diffusion"
          hint="Laisser vide pour diffuser indéfiniement"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          clearable
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" :allowed-dates="allowedDates" locale="fr-FR" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(date)">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-btn
      class="mt-4"
      color="green lighten-1 white--text"
      :disabled="!selected_files || selected_files.length <= 0 || loading"
      block
      @click="saveCustomSlide()"
      >Enregistrer</v-btn
    >
  </div>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";
import snackBar from "@/utils/mixins/snackBar";

export default {
  name: "CustomSlideUpload",

  components: { SemipolarSpinner },
  props: {
    company_type: {
      type: String,
      default: "concession",
    },
    current_custom_slides: { type: Array, default: () => [] },
    company_name: { type: String, default: "" },
    date_validity: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [snackBar],

  data() {
    return {
      selected_files: [],
      mapping: {
        concession: { add_slide: "/concession/custom_slide" },
        groupe: { add_slide: "/groupe/custom_slide/" },
        franchise: { add_slide: "/franchise/custom_slide/" },
      },

      date: "",
      menu: false,
      modal: false,

      loading: false,

      slidesRules: [
        (value) => {
          if (value && value.length > 0 && value.length <= 5 && value.filter((el) => el.size >= 1000 * 1000 * 1000).length > 0)
            return `Un fichier est trop volumineux pour être enregistré (1GB maximum par fichier)`;
          return true;
        },
        (value) => {
          if (value && value.length + this.current_custom_slides.length > 10)
            return `Il est impossible de cumuler plus de 10 fichiers ( fichiers selectionnés + fichiers déjà sauvegardés )`;
          return true;
        },
      ],
    };
  },

  methods: {
    /**
     * allow only date in the futur
     */
    allowedDates(date) {
      return new Date(date) > new Date();
    },

    async saveCustomSlide() {
      let config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          this.$Progress.set(((progressEvent.loaded / 1.4 / progressEvent.total) * 100).toFixed(2));
        },
      };
      try {
        this.loading = true;
        for (let i = 0; i < this.selected_files.length; i++) {
          let url =
            this.company_type === "groupe" || this.company_type === "franchise"
              ? `${this.mapping[this.company_type].add_slide}${this.company_name}`
              : this.mapping[this.company_type].add_slide;

          const formData = new FormData();
          formData.append(`file_${i}`, this.selected_files[i]);

          config.params = { bytes_length: this.selected_files[i].size };
          if (this.date?.length > 0) config.params.expiration_date = this.date;

          this.$Progress.tempTransition({
            speed: "0s",
            opacity: "0.6s",
            termination: 400,
          });

          let { data } = await this.$axios.post(url, formData, config);

          this.$Progress.finish();

          this.$emit("on-save-slide", { name: this.company_name, data }); // data typeof custom_slide
          this.displaySuccessSnack(`Le nouveaux slide "${this.selected_files[i].name}" a bien été enregistré !`);
        }
        this.selected_files = null;
        this.date = '';
      } catch (err) {
        console.error(err);
        this.displayErrorSnack(`Une erreur s'est produite lors de la sauvegarde des nouveaux slides ... (${err})`);
        this.$Progress.fail();
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    formatDate() {
      this.date;

      if (this.date?.length <= 0) return "";

      const format = new Intl.DateTimeFormat("fr-FR", { weekday: "long", day: "2-digit", month: "long", year: "numeric" });
      const [{ value: weekday }, , { value: day }, , { value: month }, , { value: year }] = format.formatToParts(
        new Date(this.date)
      );

      return `${weekday} ${day} ${month} ${year}`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
